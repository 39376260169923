import React, { Component } from 'react'

export default class Products extends Component {
  render() {
    return (
      <div>
				<section className="colorlib-work" data-section="products">
					<div className="colorlib-narrow-content">
						<div className="row">
							<div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
								<span className="heading-meta">My Work</span>
								<h2 className="colorlib-heading animate-box">Recent Projects</h2>
							</div>
						</div>

            <div className="overlay" />

            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                  <div className="slider-text-inner js-fullheight">
                    <div className="desc">
                      <h3>Tetris Deluxe</h3>
                      <span>Yet another clone of Tetris in Unity</span>
                      <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <p>
                          <iframe width="560" height="315" src="https://www.youtube.com/embed/of_H2zJcQU4"
                          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen></iframe>
                        </p>
                      </div>
                      <p>
                        <ul style={{listStyle: "none"}}>
                          <li><span><a href="https://jaeydoesunity.s3.amazonaws.com/TetrisDeluxe-mac.zip"><i className="icon-appleinc" /> Tetris Deluxe for Macs</a></span></li>
                          <li><span><a href="https://jaeydoesunity.s3.amazonaws.com/TetrisDeluxe-win.zip"><i className="icon-windows" /> Tetris Deluxe for Windows</a></span></li>
                        </ul>
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>

					</div>
				</section>
      </div>
    )
  }
}
